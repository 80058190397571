<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { useAlertBoxStore } from "@/stores/alertBox";

const typeClass = ref();
const alertBox = useAlertBoxStore();
const message = computed(() => {
  return alertBox.alertBoxMessage;
});

watch(
  message,
  (value) => {
    handleShown();
    generateType(alertBox.getAlertBoxType);
  },
  { deep: true }
);

let alertTimer: any = null;
const handleShown = () => {
  clearTimeout(alertTimer);
  alertTimer = window.setTimeout(() => {
    alertBox.deactivateAlertBox(false);
  }, alertBox.getAlertTimerMilis);
};

const generateType = (type: string) => {
  typeClass.value = `alert alert-${type} fade show m-1 border-1`;
};

generateType(alertBox.getAlertBoxType);
handleShown();
</script>

<template>
  <div
    :class="typeClass"
    id="alert-box-container"
    role="alert"
    v-if="alertBox.showAlertBox"
  >
    {{ alertBox.alertBoxMessage }}
  </div>
</template>
