import { usePlayerOnlineStore } from "./../stores/playersOnline";
import { useAuthStore } from "@/stores/auth";
import type { MercureEventListener } from "./EventListenerInterface";

class PlayersOnline implements MercureEventListener {
  async listener(event: MessageEvent) {
    console.log('listener finally', event.data)
    const playerOnlineStore = usePlayerOnlineStore();
    const data = JSON.parse(event.data);
    playerOnlineStore.pushPlayerOnline(data);
  }

  topic() {
    return "backoffice/online-players";
  }

  on() {
    return "message";
  }
}

export default PlayersOnline;
