import { defineStore } from "pinia";
import { useCookies } from "vue3-cookies";
import type {
  BrandInterface,
  UserBrandItemInterface,
} from "./contracts/BrandInterface";

const { cookies } = useCookies();
const backofficeBrands = "casino_backoffice_user_activ_brands";
export const useBrandStore = defineStore({
  id: "brand",
  state: () => ({
    brands: <Array<BrandInterface>>pullBrands(),
    activeBrands: <Array<BrandInterface>>pullActiveBrands(),
  }),
  getters: {
    getBrands: (state) => state.brands,
    getActiveBrands: (state) => state.brands.filter((a) => a.checked),
  },
  actions: {
    setBrands(brands: Array<BrandInterface>) {
      this.brands = brands;
      cookies.set(backofficeBrands, JSON.stringify(brands));
    },
    clearData() {
      cookies.remove(backofficeBrands);
    },
    getActiveBrandsDropdown(): UserBrandItemInterface[] {
      const dropdown: UserBrandItemInterface[] = [];
      this.brands.forEach((a: BrandInterface) => {
        if (a.checked == true) dropdown.push({ id: a.id, name: a.name });
      });
      return dropdown;
    },
  },
});

const pullBrands = (): BrandInterface[] => {
  const brands = JSON.parse(
    cookies.get(backofficeBrands)
  ) as unknown as BrandInterface[];
  return brands;
};

const pullActiveBrands = (): BrandInterface[] => {
  const arr: BrandInterface[] = [];
  const brands = pullBrands() ?? [];
  brands.forEach((element) => {
    if (element.checked) {
      arr.push(element);
    }
  });
  return arr.length === 0 ? brands : arr;
};

