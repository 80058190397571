import PlayersOnline from "@/eventListeners/PlayersOnline";
import { SSEManager } from "./sseManager";

const eventRegister = new SSEManager([
  {
    name: "playersOnline",
    auth: false,
    event: new PlayersOnline(),
  },
  // {
  //   name: "test",
  //   auth: false,
  //   topic: "test",
  //   on: "message",
  //   listener: function (event) {
  //     return;
  //   },
  // },
]);

export default eventRegister;
