<script setup lang="ts">
import { RouterView } from "vue-router";
import { useLoaderStore } from "./stores/loader";
import { useAlertBoxStore } from "./stores/alertBox";
import LoaderVue from "./components/UI/Loader.vue";
import AlertBoxNotify from "./components/UI/AlertBoxNotify.vue";
import { useAuthStore } from "@/stores/auth";
import eventRegister from "@/lib/mercury/eventRegister";
import { onMounted } from "vue";
const store = useAuthStore();
const role = store?.$state?.role?.name;
const loader = useLoaderStore();
const alertBox = useAlertBoxStore();

if (!store.user !== null) {
  eventRegister.authorizationHook();
}
eventRegister.nonAuthorizationHook();
</script>

<template>
  <RouterView />
  <Teleport to="body" v-if="loader.getIsLoading">
    <LoaderVue></LoaderVue>
  </Teleport>
  <Teleport to="#app-main" v-if="alertBox.showAlert">
    <AlertBoxNotify></AlertBoxNotify>
  </Teleport>
</template>

<style lang="scss">
@import "@/assets/styles/scss/base.scss";
</style>
<style>
@import "@/assets/vendors/@fortawesome/fontawesome-free/css/all.min.css";
@import "@/assets/vendors/ionicons-npm/css/ionicons.css";
@import "@/assets/vendors/linearicons-master/dist/web-font/style.css";
@import "@/assets/vendors/pixeden-stroke-7-icon-master/pe-icon-7-stroke/dist/pe-icon-7-stroke.css";
@import "vue-select/dist/vue-select.css";
@import "vue3-easy-data-table/dist/style.css";
@import "@vuepic/vue-datepicker/dist/main.css";
</style>
