import router from "@/router";
import { masterRole } from "../data/constants";
import { useAuthStore } from "@/stores/auth";

export const permissionChecker = () => {
  router.beforeEach(async (to: any, from: any, next: any) => {
    const authStore = useAuthStore();
    const role = authStore.getRoleName();
    const permissions: any = authStore.getRolePermissions();
    let result;
    if (role !== masterRole) {
      result = getResultPerms(to, permissions, role);
      if (result) {
        next();
      } else if (!result) {
        next(from);
        alert("You do not have permission!");
      }
    } else {
      next();
    }
  });
};

const getResultPerms = (to: any, permissions: Array<any>, role: any) => {
  let perm = false;
  if (
    !window.location.href.includes("login") &&
    to.path !== "/login" &&
    to.meta.checkPermission
  ) {
    const metaPerms = to.meta.page + "." + to.meta.type;
    console.log(permissions);
    for (const [key, value] of Object.entries(permissions)) {
      if (value.name == metaPerms) {
        perm = true;
        return perm;
      } else {
        perm = false;
      }
    }
    return perm;
  }
  return true;
};
/*
export const getPermissionValue = (selector: any) => {
  try {
    return eval("permissions." + selector);
  } catch (e) {
    return undefined;
  }
};
*/
