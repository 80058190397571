import { useAuthStore } from "../stores/auth";
import { computed } from "vue";

export function getAccessToken() {
  const store = useAuthStore();
  return store.$state.accessToken;
}

export async function sendingRefreshToken() {
  const store = useAuthStore();
  const refreshToken = store.getRefreshToken();
  console.log("Sending Refresh token...");
  if (refreshToken == null) {
    logout();
    return;
  }
  return store.sendRefreshToken();
}

export function logout() {
  const store = useAuthStore();
  store.logout();
}

export function rememberMe() {
  const store = useAuthStore();
  console.log("access token");
  const isRemembered = computed(() => store.$state.isRemembered);
  if (isRemembered.value === null) {
    return true;
  }
  const rememberDate = new Date(isRemembered.value);
  rememberDate.setMinutes(rememberDate.getMinutes() + 10);
  const currentDateTime = new Date();
  console.log(currentDateTime > rememberDate);
  return currentDateTime > rememberDate;
}

export function setingActivity() {
  const store = useAuthStore();
  store.setActivity();
}

export function gettingActivity() {
  const store = useAuthStore();
  return store.getActivity();
}

export async function snoozeRequest(iterationCount = 5, iterationTime = 1000) {
  let refreshTokenWait = null;
  let counter = 0;
  while (counter < iterationCount) {
    refreshTokenWait = localStorage.getItem("refreshTokenWait");
    if (refreshTokenWait == null) {
      // console.log("snooze done");
      break;
    }
    // console.log("wait...");
    await sleep(iterationTime);
    counter++;
  }
}

const sleep = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
