import { defineStore } from "pinia";

export const useAlertBoxStore = defineStore({
  id: "alertBox",
  state: () => ({
    showAlertBox: <boolean>false,
    alertBoxType: <string>"",
    alertBoxMessage: <string>"",
    timerMilis: <number>4000,
  }),
  getters: {
    showAlert: (state) => state.showAlertBox,
    getAlertBoxType: (state) => state.alertBoxType,
    getAlertBoxMessage: (state) => state.alertBoxMessage,
    getAlertTimerMilis: (state) => state.timerMilis,
  },
  actions: {
    activeAlertBox(type: string, message: string) {
      this.alertBoxMessage = "";
      this.showAlertBox = true;
      this.alertBoxType = type;
      this.alertBoxMessage = message;
    },
    deactivateAlertBox(payload: boolean) {
      this.showAlertBox = payload;
    },
  },
});
