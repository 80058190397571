import { SSEClient } from "./sseClient";
import type { SSeRegisterEvent } from "./interfaces";

export class SSEManager {
  register: Array<SSeRegisterEvent>;
  events: Array<{ client: SSEClient; name: string; auth: boolean }> = [];
  constructor(register: Array<SSeRegisterEvent>) {
    this.register = register;
  }
  authorizationHook() {
    for (const registerEvent of this.register) {
      if (registerEvent.auth) {
        this.create(registerEvent);
      }
    }
  }
  nonAuthorizationHook() {
    for (const registerEvent of this.register) {
      if (!registerEvent.auth) {
        this.create(registerEvent);
      }
    }
  }
  logoutAuthorizationHook() {
    let i: number;

    for (i = 0; i < this.events.length; i++) {
      if (this.events[i].auth) {
        this.events[i].client.unsubscribe();
        this.events.splice(i, 1);
        i--;
        //delete this.events[i];
      }
    }
  }
  create(registerEvent: SSeRegisterEvent) {
    const client = new SSEClient(registerEvent.event);
    if (registerEvent.auth) {
      client.subscribe();
    } else {
      client.subscribePublic();
    }
    this.events.push({
      client: client,
      name: registerEvent.name,
      auth: registerEvent.auth,
    });
  }
}
