import { useBrandStore } from "@/stores/brand";
import type {
  PermissionInterface,
  RoleInterface,
  UserInterface,
} from "./contracts/UserInterface";
import type {
  BrandInterface,
  UserBrandItemInterface,
} from "@/stores/contracts/BrandInterface";
import { defineStore } from "pinia";
import router from "../router/index";
import { useCookies } from "vue3-cookies";
import axios from "axios";

const baseUrl = import.meta.env.VITE_API_BACKOFFICE;
const masterRole = import.meta.env.VITE_MASTER_ROLE;

const backofficeAccessToken = "casino_backoffice_token";
const backofficeRefreshToken = "casino_backoffice_refresh_token";
const backofficeUser = "casino_backoffice_user";
const isRemembered = "casino_backoffice_user_is_remembered";
const backofficeActivity = "casino_backoffice_activity";

const { cookies } = useCookies();
export const useAuthStore = defineStore({
  id: "authentication",
  state: () => ({
    accessToken: <string | null>cookies.get(backofficeAccessToken),
    refreshToken: <string | null>pullRefreshToken(),
    isRemembered: <Date | string | null>pullIsRemembered(),
    activity: <string | Date | null>pullActivity(),
    expiresIn: <number>999999,
    user: <UserInterface | null>pullUser(),
    role: pullUserRole(),
    permissions: pullUserPermissions(),
    brands: <UserBrandItemInterface[]>pullUserBrands(),
  }),
  actions: {
    getUserId(): number | null {
      return this.user?.id ?? null;
    },
    async login(
      username: string,
      password: string,
      keepSignIn = false
    ): Promise<void> {
      await axios
        .post(`${baseUrl}/api/auth/token`, {
          grant_type: "password",
          username: username,
          password: password,
        })
        .then(async (response) => {
          this.setTokens(response, keepSignIn);
          await this.setUserData(true);
        });
    },
    async setUserData(redirect = false): Promise<void> {
      axios
        .get(`${baseUrl}/users/me`, {
          headers: {
            Authorization: "Bearer " + this.accessToken,
          },
        })
        .then((response) => {
          this.user = response.data.data as UserInterface;
          this.setCookieUser(this.user);
          this.setRoleAndPermissions(this.user);
          this.setBrands(this.user);
          this.setActivity();
          if (redirect) {
            router.push("/");
          }
        });
    },
    async sendRefreshToken() {
      const newTokenResponse = await axios
        .post(`${baseUrl}/api/auth/token`, {
          grant_type: "refresh_token",
          refresh_token: this.refreshToken,
        })
        .catch((err) => {
          console.log(err);
          if (err.code == "ERR_NETWORK") {
            this.logout();
          }
        });
      this.setTokens(newTokenResponse);
      this.setUserData();
    },
    logout() {
      this.user = null;
      this.accessToken = null;
      this.refreshToken = null;
      this.isRemembered = null;
      cookies.remove(backofficeUser);
      cookies.remove(backofficeAccessToken);
      localStorage.removeItem(backofficeRefreshToken);
      localStorage.removeItem(isRemembered);
      localStorage.removeItem("refreshTokenWait");
      const brandStore = useBrandStore();
      brandStore.clearData();
      router.push("/login");
    },
    getAccessToken() {
      return this.accessToken;
    },
    getRolePermissions() {
      return this.role?.permissions;
    },
    getRoleName() {
      return this.role?.name;
    },

    setTokens(response: any, keepSignIn = false) {
      this.refreshToken = response.data.refresh_token;
      this.accessToken = response.data.access_token;
      this.expiresIn = response.data.expires_in;
      cookies.set(
        backofficeAccessToken,
        this.accessToken as string,
        this.expiresIn
      );
      localStorage.setItem(
        backofficeRefreshToken,
        JSON.stringify(this.refreshToken)
      );
      if (keepSignIn) {
        this.isRemembered = new Date();
        localStorage.setItem(isRemembered, JSON.stringify(this.isRemembered));
      }
    },
    setCookieUser(user: UserInterface) {
      cookies.set(backofficeUser, JSON.stringify(user), this.expiresIn * 2);
    },
    setRoleAndPermissions(user: UserInterface) {
      this.role = user.role;
      if (this.role !== masterRole) {
        this.permissions = user.role ? user.role.permissions : null;
      }
    },
    setBrands(user: UserInterface) {
      this.brands = user.brands;
      setBrandStoreBrands(this.brands);
    },
    setActivity() {
      if (this.user) {
        this.activity = new Date();
        cookies.set(
          backofficeActivity,
          JSON.stringify(this.activity),
          this.expiresIn
        );
      }
    },
    getActivity() {
      this.activity = pullActivity();
      if (this.isRemembered) {
        return true;
      }
      return this.activity !== null;
    },
    getRefreshToken() {
      this.refreshToken = pullRefreshToken();
      return this.refreshToken;
    },
  },
});

const pullUser = (): UserInterface | null => {
  return cookies.get(backofficeUser) as unknown as UserInterface;
};
const pullUserRole = (): RoleInterface | null => {
  return pullUser()?.role ?? null;
};
const pullUserPermissions = (): PermissionInterface[] | null => {
  if (pullUser()?.role.name !== masterRole) {
    return pullUser()?.role.permissions ?? null;
  }
  return null;
};
const pullUserBrands = (): UserBrandItemInterface[] | null => {
  const userBrands = pullUser()?.brands ?? [];
  // setBrandStoreBrands(userBrands);
  return userBrands;
};

const pullActivity = () => {
  return cookies.get(backofficeActivity);
};

const pullRefreshToken = (): string | null => {
  const rfToken = localStorage.getItem(backofficeRefreshToken);
  if (rfToken !== null) {
    return JSON.parse(rfToken);
  }
  return null;
};

const pullIsRemembered = (): Date | string | null => {
  const remember = localStorage.getItem(isRemembered);
  if (remember !== null) {
    return JSON.parse(remember);
  }
  return null;
};

// setting in brandStores
const setBrandStoreBrands = (userBrands: UserBrandItemInterface[]) => {
  const brandStore = useBrandStore();
  userBrands.forEach((brand: BrandInterface) => {
    brand.active = false;
    brand.checked = true;
  });
  brandStore.setBrands(userBrands);
};
