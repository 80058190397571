import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "../stores/auth";
import { sendingRefreshToken } from "../services/authInterceptor";
import { permissionChecker } from "@/services/permissionChecker/permissionChecker";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      // component: HomeView,
      component: () => import("../views/LayoutView.vue"),
      children: [
        {
          path: "/",
          name: "dashboard",
          component: () => import("../views/Dashboard.vue"),
          meta: {
            showBreadcrumb: false,
            checkPermission: false,
          },
        },
        {
          path: "players",
          name: "players",
          meta: {
            showBreadcrumb: false,
            checkPermission: true,
            page: "player.general",
            parent: "player",
            type: "view",
          },
          component: () => import("../views/Players/PlayersList.vue"),
        },
        {
          path: "/players/:id",
          name: "player_profile",
          meta: {
            checkPermission: true,
            page: "player.general",
            parent: "player",
            type: "view",
          },
          component: () => import("../views/Players/PlayerProfile.vue"),

          children: [
            {
              path: "/players/:id",
              name: "personal_data",
              meta: {
                checkPermission: false,
                page: "player,general",
                parent: "player",
                type: "view",
              },
              component: () =>
                import(
                  "../components/PlayerProfile/PersonalData/PersonalData.vue"
                ),
            },
            {
              path: "/players/:id/edit",
              name: "personal_data_edit",
              meta: {
                checkPermission: true,
                page: "player.general",
                parent: "player",
                type: "edit",
              },
              component: () =>
                import(
                  "../components/PlayerProfile/PersonalData/PersonalDataEdit.vue"
                ),
            },
            {
              path: "/players/:id/account-info",
              name: "account_info",
              meta: {
                checkPermission: true,
                page: "player.general",
                parent: "player",
                type: "view",
              },
              component: () =>
                import(
                  "../components/PlayerProfile/AccountInfo/AccountInfo.vue"
                ),
            },
            {
              path: "/players/account-info/:id/edit",
              name: "account_info_edit",

              meta: {
                showBreadcrumb: false,
                checkPermission: true,
                page: "player.general",
                parent: "player",
                type: "edit",
              },
              component: () =>
                import(
                  "../components/PlayerProfile/AccountInfo/AccountDataEdit.vue"
                ),
            },
            {
              path: "/players/:id/self-exclusion",
              name: "self_exclusion",

              meta: {
                checkPermission: true,
                page: "player.self_exclusion",
                parent: "player",
                type: "view",
              },
              component: () =>
                import(
                  "../components/PlayerProfile/SelfExclusion/SelfExclusion.vue"
                ),
            },
            {
              path: "/players/:id/self-exclusion/add",
              name: "self_exclusion_add",
              meta: {
                checkPermission: true,
                page: "player.self_exclusion",
                parent: "player",
                type: "create",
              },
              component: () =>
                import(
                  "../components/PlayerProfile/SelfExclusion/AddSelfExclusion.vue"
                ),
            },
            {
              path: "/players/:id/multiple-brand-mapper",
              name: "multiple_brand_mapper",
              component: () =>
                import(
                  "../components/PlayerProfile/MultipleBrandMapper/MultipleBrandMapper.vue"
                ),
            },
            {
              path: "/players/:id/notes",
              name: "notes",
              component: () => import("../components/PlayerProfile/Notes.vue"),
            },
            {
              path: "/players/:id/protection-limit",
              name: "protection_limits",
              meta: {
                page: "player.protection_limit",
                type: "view",
                checkPermission: true,
              },
              component: () =>
                import(
                  "../components/PlayerProfile/ProtectionLimits/ProtectionLimitsHome.vue"
                ),
            },
            {
              path: "/players/:id/financial-data",
              name: "financial_data",
              component: () =>
                import(
                  "../components/PlayerProfile/FinancialData/FinancialDataHome.vue"
                ),
              children: [
                {
                  path: "/players/:id/financial-data",
                  name: "summary",
                  component: () =>
                    import(
                      "../components/PlayerProfile/FinancialData/Summary.vue"
                    ),
                },
                {
                  path: "/players/:id/financial-data/deposit",
                  name: "deposit",
                  component: () =>
                    import(
                      "../components/PlayerProfile/FinancialData/DepositDatatable.vue"
                    ),
                },
                {
                  path: "/players/:id/financial-data/payouts",
                  name: "payouts",
                  component: () =>
                    import(
                      "../components/PlayerProfile/FinancialData/PayoutsDatatable.vue"
                    ),
                },
                {
                  path: "/players/:id/financial-data/high-wins",
                  name: "high_wins",
                  component: () =>
                    import(
                      "../components/PlayerProfile/FinancialData/HighWinsDatatable.vue"
                    ),
                },
                {
                  path: "/players/:id/financial-data/balance-data",
                  name: "balance_data",
                  component: () =>
                    import(
                      "../components/PlayerProfile/FinancialData/BalanceDatatable.vue"
                    ),
                },
                {
                  path: "/players/:id/financial-data/game-providers-data",
                  name: "game_providers_data",
                  component: () =>
                    import(
                      "../components/PlayerProfile/FinancialData/GameProvidersDatatable.vue"
                    ),
                },
                {
                  path: "/players/:id/financial-data/bonus-usage",
                  name: "bonus_usage",
                  component: () =>
                    import(
                      "../components/PlayerProfile/FinancialData/BonusUsageDatatable.vue"
                    ),
                },
                {
                  path: "/players/:id/financial-data/liability",
                  name: "liability",
                  component: () =>
                    import(
                      "../components/PlayerProfile/FinancialData/LiabilityReport.vue"
                    ),
                },
              ],
            },
            {
              path: "/players/:id/legal",
              name: "legal",
              component: () => import("../components/PlayerProfile/Legal.vue"),
            },
            {
              path: "/players/:id/kyc",
              name: "kyc",
              component: () => import("../components/PlayerProfile/KYC.vue"),
            },
            {
              path: "/players/:id/risk-analysis",
              name: "risk_analysis",
              component: () =>
                import("../components/PlayerProfile/RiskAnalysis.vue"),
            },

            {
              path: "/players/:id/notes",
              name: "kyc",
              component: () => import("../components/PlayerProfile/Notes.vue"),
            },
          ],
        },
        //bonu refactor
        {
          path: "/bonuses-refactor",
          name: "bonuses_refactor",
          component: () =>
            import("../views/BonusFeature/CreateBonusWrapper.vue"),
          meta: { showBreadcrumb: false },
        },

        {
          path: "/bonuses",
          name: "bonuses",
          component: () => import("../views/Bonus.vue"),
          meta: { showBreadcrumb: false },
          children: [
            {
              path: "edit/:bonusId",
              name: "bonuses_edit",
              component: () =>
                import("../views/BonusFeature/CreateBonusWrapper.vue"),
              meta: { showBreadcrumb: false },
            },

            {
              path: "add",
              name: "bonuses_add",
              component: () =>
                import("../views/BonusFeature/CreateBonusWrapper.vue"),
              meta: { showBreadcrumb: false },
            },

            {
              path: "/bonuses/freespin-configuration/list",
              name: "freespin_configuration_list",
              meta: {
                showBreadcrumb: true,
                checkPermission: false,
                page: "bonuses",
                parent: "player",
                type: "view",
              },
              component: () =>
                import(
                  "../components/Freespins/FreeSpinsConfigurationList.vue"
                ),
            },
            {
              path: "/bonuses/freespin-configuration/:id",
              name: "freespin_configuration_single",
              meta: {
                showBreadcrumb: true,
                checkPermission: false,
                page: "bonuses",
                parent: "player",
                type: "view",
              },
              component: () =>
                import(
                  "../views/Bonus/FreeSpins/FreespinConfiguration/GetOneFreespinConfiguration.vue"
                ),
            },
            {
              path: "types-list",
              name: "bonus_type_list",
              component: () =>
                import("../components/Bonuses/BonusTypesList.vue"),
            },

            {
              path: "bonuses-type/add",
              name: "bonus_type",
              meta: {
                checkPermission: true,
                page: "bonuses.bonus_type",
                parent: "player",
                type: "create",
              },
              component: () => import("../components/Bonuses/BonusType.vue"),
            },
            {
              path: "bonus-type/:bonusTypeId",
              name: "bonus_type_view",
              meta: {
                checkPermission: true,
                page: "bonuses.bonus_type",
                parent: "player",
                type: "view",
              },
              component: () =>
                import("../components/Bonuses/ViewBonusType.vue"),
            },
            {
              path: "list",
              name: "bonus_list",
              component: () => import("../components/Bonuses/BonusesList.vue"),
              children: [],
            },

            {
              path: "/bonuses/freespin-configuration/add",
              name: "free_spins_configuration)add",
              meta: {
                showBreadcrumb: true,
                checkPermission: false,
                page: "bonuses.freespin",
                parent: "player",
                type: "create",
              },
              component: () =>
                import(
                  "../views/Bonus/FreeSpins/FreespinConfiguration/FreeSpinsConfiguration.vue"
                ),
            },
          ],
        },
        {
          path: "/assets",
          name: "assets",
          meta: { showBreadcrumb: false },
          component: () => import("../views/AssetsView.vue"),
          children: [
            {
              path: "list",
              name: "assets_list",
              component: () => import("../components/Assets/AssetsList.vue"),
            },
            {
              path: "/assets/list/upload",
              name: "assets_upload",
              component: () => import("../components/Assets/UploadAsset.vue"),
            },
          ],
        },

        {
          path: "/affiliate",
          name: "affiliate",
          component: () => import("../views/Affiliates/AffiliateView.vue"),
          meta: {
            checkPermission: true,
            page: "affiliate",
            parent: "player",
            type: "view",
          },
          children: [
            {
              path: "players",
              name: "players_affiliate",
              component: () =>
                import("../components/Affiliate/PlayersAffiliate.vue"),
            },
            {
              path: "activity",
              name: "activity_affiliate",
              component: () =>
                import("../components/Affiliate/ActivityAffiliate.vue"),
            },
            {
              path: "partners",
              name: "partners_affiliate",
              component: () =>
                import("../components/Affiliate/PartnersAffiliate.vue"),
            },
          ],
        },
        {
          path: "/bonus-view/:id",
          name: "bonus",
          meta: {
            checkPermission: true,
            page: "bonuses.general",
            parent: "player",
            type: "view",
          },
          component: () => import("../views/Bonus/BonusView.vue"),
        },
        {
          path: "/payout",
          name: "payout",
          meta: {
            checkPermission: true,
            page: "payout",
            parent: "player",
            type: "view",
          },
          component: () => import("../views/Payout/PayoutView.vue"),
        },
        {
          path: "country-groups",
          name: "list_country_groups",
          meta: {
            checkPermission: true,
            page: "brand.country_groups",
            parent: "player",
            type: "view",
          },
          component: () =>
            import("../views/CountryGroups/ListCountryGroups.vue"),
        },
        {
          path: "country-groups/add",
          name: "create_country_group",
          meta: {
            checkPermission: true,
            page: "brand.country_groups",
            parent: "player",
            type: "edit",
          },
          component: () =>
            import("../views/CountryGroups/CreateCountryGroup.vue"),
        },
        {
          path: "country-groups/edit/:id",
          name: "edit_country_group",
          meta: {
            checkPermission: true,
            page: "brand.country-groups",
            parent: "player",
            type: "edit",
          },
          component: () =>
            import("../views/CountryGroups/EditCountryGroup.vue"),
        },
        {
          path: "country-groups/delete/:id",
          name: "delete_country_group",
          meta: {
            checkPermission: true,
            page: "brand.country-groups",
            parent: "player",
            type: "edit",
          },
          component: () =>
            import("../views/CountryGroups/DeleteCountryGroup.vue"),
        },
        {
          path: "banners",
          name: "banners",

          component: () => import("../views/Banners/BannersView.vue"),
        },
        {
          path: "promotions",
          name: "promotions",

          component: () => import("../views/Promotions/PromotionsList.vue"),
        },
        {
          path: "promotion/add/:bonusId",
          name: "create_promotion",

          component: () => import("../views/Promotions/PromotionWrapper.vue"),
        },
        {
          path: "promotions/:id",
          name: "edit_promotion",

          component: () => import("../views/Promotions/edit/EditPromotion.vue"),
        },

        {
          path: "/payout-view/:id",
          name: "payout_view",
          meta: {
            checkPermission: true,
            page: "payout",
            parent: "player",
            type: "actions",
          },
          component: () => import("../views/Payout/PayoutActionsView.vue"),
        },
        {
          path: "/games",
          name: "games",
          component: () => import("../views/Games.vue"),
          meta: {
            showBreadcrumb: false,
            checkPermission: true,
            page: "games",
            parent: "player",
            type: "view",
          },
          children: [
            {
              path: "edit/:id",
              name: "game_edit",
              component: () => import("../components/Page/Games/EditGame.vue"),
              meta: {
                checkPermission: true,
                page: "games",
                parent: "player",
                type: "edit",
              },
            },
            {
              path: "deleted",
              name: "game_deleted_list",
              component: () =>
                import("../components/Page/Games/DeletedGames.vue"),
            },
            {
              path: "list",
              name: "game_list",
              component: () =>
                import("../components/Page/Games/ManageGame.vue"),
            },
            {
              path: "countries-restriction",
              name: "countries_restriction",
              component: () =>
                import(
                  "../components/Page/Games/CountryRestrictions/CountriesRestriction.vue"
                ),
              meta: { showBreadcrumb: false },
              children: [
                {
                  path: "provider",
                  name: "providerRestriction",
                  component: () =>
                    import(
                      "../components/Page/Games/CountryRestrictions/ProviderCountryRestriction.vue"
                    ),
                },
                {
                  path: "game",
                  name: "gameCountryRestriction",
                  component: () =>
                    import(
                      "../components/Page/Games/CountryRestrictions/GameCountryRestriction.vue"
                    ),
                },
                {
                  path: "supplier",
                  name: "supplierRestriction",
                  component: () =>
                    import(
                      "../components/Page/Games/CountryRestrictions/SupplierCountryRestriction.vue"
                    ),
                } /*
                  {
                    path: "edit/:id",
                    name: "editRestriction",
                    component: () => import("../components/Page/Games/CountryRestrictions/EditRestriction.vue")
                  }, */,
              ],
            },

            {
              path: "/games/countries-restriction/add-restriction",
              name: "addRestriction",
              component: () =>
                import(
                  "../components/Page/Games/CountryRestrictions/AddRestriction.vue"
                ),
            },
            {
              path: "/games/countries-restriction/edit/:id",
              name: "editRestriction",
              component: () =>
                import(
                  "../components/Page/Games/CountryRestrictions/EditRestriction.vue"
                ),
            },
          ],
        },
        {
          path: "brand/:brandId/cms",
          name: "CMS",
          component: () => import("../views/CmsView.vue"),
          meta: { showBreadcrumb: false },
          children: [
            {
              path: "banners/:tag/add",
              name: "add_banner",
              meta: {
                checkPermission: true,
                page: "brand.banners",
                parent: "player",
                type: "create",
              },
              component: () => import("../views/Banners/AddBanner.vue"),
            },

            {
              path: "brand-settings",
              name: "brand",
              meta: {
                checkPermission: true,
                page: "brand.settings",
                parent: "player",
                type: "edit",
              },

              component: () => import("../views/BrandView.vue"),
            },
            {
              path: "list",
              name: "list",
              component: () => import("../views/CMS/CmsList.vue"),
            },
            {
              path: "brand-settings-view",
              name: "brand_view",
              meta: {
                checkPermission: true,
                page: "brand.settings",
                parent: "player",
                type: "view",
              },
              component: () =>
                import("../views/BrandSettings/BrandSettingsView.vue"),
            },
            {
              path: "game-order/:categoryId",
              name: "game_order",
              component: () =>
                import("../components/GameManagement/GameOrder.vue"),
            },
            {
              path: "game-category",
              name: "game_category",
              component: () =>
                import("../components/GameManagement/GameCategory.vue"),
            },
            {
              path: "game-category/add",
              name: "game_category_add",
              meta: {
                checkPermission: true,
                page: "brand.category",
                parent: "player",
                type: "create",
              },
              component: () =>
                import("../components/GameManagement/AddGameCategory.vue"),
            },
            {
              path: "modules-configuration",
              name: "modules_configuration",
              component: () =>
                import("../components/Modules/ModulesConfig.vue"),
            },
            {
              path: "risk-settings-view",
              name: "risk_settings-view",
              meta: {
                checkPermission: true,
                page: "risk",
                parent: "player",
                type: "view",
              },

              component: () => import("../views/Risk/RiskView.vue"),
            },
            {
              path: "risk-settings",
              name: "risk_settings",
              meta: {
                checkPermission: true,
                page: "risk",
                parent: "player",
                type: "edit",
              },
              component: () =>
                import("../components/RiskSettings/RiskWrapper.vue"),
            },
            {
              path: "editor/:id?",
              name: "add_cms",
              // meta: {
              //   checkPermission: true,
              //   page: "brand.translations",
              //   parent: "player",
              //   type: "view",
              // },
              component: () => import("../views/CMS/PageEditView.vue"),
            },
            {
              path: "translations/:translationSelector",
              name: "translations",
              meta: {
                checkPermission: true,
                page: "brand.translations",
                parent: "player",
                type: "view",
              },
              component: () => import("../views/TranslationsView.vue"),
            },
            {
              path: "email-templates/:id/:languageId",
              name: "email-templates",
              meta: {
                checkPermission: true,
                page: "brand.email_templates",
                parent: "player",
                type: "view",
              },
              component: () => import("../views/EmailTemplatesEditorNew.vue"),
            },
            {
              path: "email-templates-list",
              name: "email-templates-list",
              component: () => import("../views/EmailTemplatesList.vue"),
            },
            {
              path: "faq",
              name: "faq",
              component: () => import("../views/FAQ/FaqView.vue"),
              meta: {
                checkPermission: true,
                page: "brand.faq",
                parent: "player",
                type: "view",
              },
            },
            /*
            {
              path: "country-groups",
              name: "list_country_groups",
              meta: {
                checkPermission: true,
                page: "brand.country_groups",
                parent: "player",
                type: "view",
              },
              component: () =>
                import("../views/CountryGroups/ListCountryGroups.vue"),
            },
            {
              path: "country-groups/add",
              name: "create_country_group",
              meta: {
                checkPermission: true,
                page: "brand.country_groups",
                parent: "player",
                type: "edit",
              },
              component: () =>
                import("../views/CountryGroups/CreateCountryGroup.vue"),
            },
            {
              path: "country-groups/edit/:id",
              name: "edit_country_group",
              meta: {
                checkPermission: true,
                page: "brand.country-groups",
                parent: "player",
                type: "edit",
              },
              component: () =>
                import("../views/CountryGroups/EditCountryGroup.vue"),
            },
            {
              path: "country-groups/delete/:id",
              name: "delete_country_group",
              meta: {
                checkPermission: true,
                page: "brand.country-groups",
                parent: "player",
                type: "edit",
              },
              component: () =>
                import("../views/CountryGroups/DeleteCountryGroup.vue"),
            },
            {
              path: "banners",
              name: "banners",

              component: () => import("../views/Banners/BannersView.vue"),
            },

            {
              path: "banners/:tag/add",
              name: "add_banner",
              meta: {
                checkPermission: true,
                page: "brand.banners",
                parent: "player",
                type: "create",
              },
              component: () => import("../views/Banners/AddBanner.vue"),
            },
            */
            {
              path: "terms-and-conditions",
              name: "terms_and_conditions",
              component: () =>
                import(
                  "../views/CMS/TermsAndConditions/TermsAndConditionsList.vue"
                ),
            },
            {
              path: "terms-and-conditions/:id",
              name: "terms_and_conditions_edit",
              meta: {
                checkPermission: true,
                page: "brand.cms",
                parent: "player",
                type: "edit",
              },
              component: () =>
                import(
                  "../views/CMS/TermsAndConditions/EditTermsAndConditions.vue"
                ),
            },
            {
              path: "terms-and-conditions/add",
              name: "terms_and_conditions_add",
              meta: {
                checkPermission: true,
                page: "brand.cms",
                parent: "player",
                type: "edit",
              },
              component: () =>
                import(
                  "../views/CMS/TermsAndConditions/AddTermsAndConditions.vue"
                ),
            },
            {
              path: "privacy-policy",
              name: "privacy_policy_list",
              component: () =>
                import("../views/CMS/PrivacyPolicy/PrivacyPolicyList.vue"),
            },
            {
              path: "privacy-policy/:id",
              name: "privacy_policy_edit",
              meta: {
                checkPermission: true,
                page: "brand.cms",
                parent: "player",
                type: "edit",
              },
              component: () =>
                import("../views/CMS/PrivacyPolicy/EditPrivacyPolicy.vue"),
            },
            {
              path: "privacy-policy/add",
              name: "privacy_policy_add",
              component: () =>
                import("../views/CMS/PrivacyPolicy/AddPrivacyPolicy.vue"),
            },
            {
              path: "bonus-rules",
              name: "bonus-rules",
              component: () =>
                import("../views/CMS/BonusRules/BonusRulesList.vue"),
            },
            {
              path: "bonus-rules/:id",
              name: "bonus_rules_edit",
              meta: {
                checkPermission: true,
                page: "brand.cms",
                parent: "player",
                type: "edit",
              },
              component: () =>
                import("../views/CMS/BonusRules/EditBonusRules.vue"),
            },
            {
              path: "bonus-rules/add",
              name: "bonus_rules_add",
              component: () =>
                import("../views/CMS/BonusRules/AddBonusRules.vue"),
            },
            {
              path: "games-explanations",
              name: "games_explanations",
              component: () =>
                import(
                  "../views/CMS/GameExplanations/GameExplanationsList.vue"
                ),
            },
            {
              path: "games-explanations/:id",
              name: "games_explanations_edit",
              meta: {
                checkPermission: true,
                page: "brand.cms",
                parent: "player",
                type: "edit",
              },
              component: () =>
                import(
                  "../views/CMS/GameExplanations/EditGameExplanations.vue"
                ),
            },
            {
              path: "games-explanations/add",
              name: "games_explanations_add",
              component: () =>
                import("../views/CMS/GameExplanations/AddGameExplanations.vue"),
            },
            {
              path: "bonus-info",
              name: "bonus-info",
              component: () =>
                import("../views/CMS/BonusInfo/BonusInfoList.vue"),
            },
            {
              path: "bonus-info/:id",
              name: "bonus_info_edit",
              meta: {
                checkPermission: true,
                page: "brand.cms",
                parent: "player",
                type: "edit",
              },
              component: () =>
                import("../views/CMS/BonusInfo/EditBonusInfo.vue"),
            },
            {
              path: "bonus-info/add",
              name: "bonus_info_add",
              component: () =>
                import("../views/CMS/BonusInfo/AddBonusInfo.vue"),
            },
            {
              path: "about",
              name: "about",
              component: () => import("../views/CMS/About/AboutList.vue"),
            },
            {
              path: "about/:id",
              name: "aout_edit",
              meta: {
                checkPermission: true,
                page: "brand.cms",
                parent: "player",
                type: "edit",
              },
              component: () => import("../views/CMS/About/EditAbout.vue"),
            },
            {
              path: "about/add",
              name: "about_add",
              component: () => import("../views/CMS/About/AddAbout.vue"),
            },
          ],
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /user/:id/posts is matched
          path: "/roles",
          name: "roles",
          component: () => import("../views/Roles/Roles.vue"),
          meta: { showBreadcrumb: false },
          children: [
            {
              path: "",
              name: "role_list",
              component: () => import("../views/Roles/ListRoles.vue"),
            },
            {
              path: "create",
              name: "role_create",
              component: () => import("../views/Roles/CreateRole.vue"),
            },
            {
              path: ":id",
              name: "role_edit",
              component: () => import("../views/Roles/EditRolePermissions.vue"),
            },
          ],
        },
        {
          // UserPosts will be rendered inside User's <router-view>
          // when /user/:id/posts is matched
          path: "/users",
          name: "users",
          component: () => import("../views/Users.vue"),
          meta: { showBreadcrumb: false },
          children: [
            {
              path: "",
              name: "users_manage",
              component: () => import("../views/Users/ManageUsers.vue"),
            },
            {
              path: "add",
              name: "user_add",
              component: () => import("../views/Users/AddUser.vue"),
            },
            {
              path: ":id",
              name: "user_edit",
              component: () => import("../views/Users/EditUser.vue"),
            },
          ],
        },
        {
          path: "/log-events",
          name: "log_events",
          component: () => import("../views/LogEventsView.vue"),
        },
        {
          path: "/:pathMatch(.*)*",
          name: "notfound",
          component: () => import("../views/NotFoundView.vue"),
        },
        {
          path: "/risk",
          name: "risk",
          component: () => import("../views/RiskView.vue"),
          meta: {
            showBreadcrumb: false,
          },
          children: [
            {
              path: "",
              name: "list_risk_category_settings",
              component: () =>
                import(
                  "@/components/RiskSettings/CategorySetings/ListCategory.vue"
                ),
            },
          ],
        },
        {
          path: "/whitelisting",
          name: "whitelisting",
          component: () => import("../views/Whitelisting/Whitelisting.vue"),
          meta: {
            showBreadcrumb: false,
          },
          children: [
            {
              path: "",
              name: "whitelisting_ip",
              component: () =>
                import(
                  "@/components/WhitelistingIpAddress/WhiteListingIpAddress.vue"
                ),
            },
          ],
        },
        {
          path: "/reports",
          name: "reports",
          component: () => import("../views/Reports.vue"),
          meta: { showBreadcrumb: false },
          children: [
            {
              path: "",
              name: "reports_list",
              component: () => import("../views/Reports/ReportCategories.vue"),
            },
            {
              path: "games_by_revenue_loss",
              name: "games_by_revenue_loss",
              meta: {
                checkPermission: true,
                page: "reports.games",
                parent: "player",
                type: "view",
              },
              component: () =>
                import("../components/Reports/GamesByRevenueLossDatatable.vue"),
            },
            {
              path: "player",
              name: "reports_player",
              meta: {
                checkPermission: true,
                page: "reports.player",
                parent: "player",
                type: "view",
              },
              component: () =>
                import("../components/Reports/PlayerReportsDatatable.vue"),
            },
            {
              path: "pep",
              name: "pep_reports",
              meta: {
                checkPermission: true,
                page: "reports.pep",
                parent: "player",
                type: "view",
              },
              component: () => import("../components/Reports/PepReports.vue"),
            },
            {
              path: "deposit",
              name: "deposit_reports",
              component: () => import("../views/Reports/Deposit.vue"),
            },
          ],
        },
      ],
    },

    {
      path: "/login",
      name: "login",
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import("../views/Login/Login.vue"),
      meta: {
        showBreadcrumb: false,
        page: "login",
        parent: "players",
        type: "view",
      },
    },
  ],
  linkExactActiveClass: "mm-active",
});

export default router;

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ["/login"];
  const master = ["/admin"];
  const masterAuth = !master.includes(to.path);
  const pagesAuthRequired = !publicPages.includes(to.path);
  let auth = useAuthStore();
  let loggedInUser = auth.user;
  if (!loggedInUser && auth.refreshToken) {
    await sendingRefreshToken();
    auth = useAuthStore();
    loggedInUser = auth.$state.user;
  }

  if (pagesAuthRequired && !loggedInUser) {
    // auth.returnUrl = to.fullPath;
    return "/login";
  }
  if (!pagesAuthRequired && loggedInUser) {
    // auth.returnUrl = to.fullPath;
    return "/";
  }
});
permissionChecker();
