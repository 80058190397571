import { defineStore } from "pinia";
import axios from "axios";

export const usePlayerOnlineStore = defineStore({
  id: "playersOnline",
  state: () => ({
    playersOnline: <any>null,
  }),
  actions: {
    async getPlayersOnline() {
      return this.playersOnline;
    },
    pushPlayerOnline(data:any) {
      this.playersOnline = data;
    },
    async fetchData() {
      await axios
        .get("/analytic/online-players")
        .then((response: any) => {
          this.playersOnline = response.data.data;
        })

        .catch((error) => console.log(error));
    },
  },
});
