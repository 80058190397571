<script setup></script>

<template>
  <div class="backdrop">
    <div
      class="loader-wrapper d-flex justify-content-center align-items-center"
    >
      <div class="loader">
        <div class="ball-pulse">
          <div class="bg-success bg-gradient"></div>
          <div class="bg-success bg-gradient"></div>
          <div class="bg-success bg-gradient"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.backdrop {
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 250;
  display: grid;
  place-items: center;
}

.loader-wrapper {
  z-index: 260;
}

.ball-pulse div {
    width: 25px;
height: 25px;
}
</style>
