import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import "./axios";
import vSelect from "vue-select";
import VueDatePicker from "@vuepic/vue-datepicker";
import Vue3EasyDataTable from "vue3-easy-data-table";
import VueApexCharts from "vue3-apexcharts";
import slider from "vue3-slider";
import * as Sentry from "@sentry/vue";

const app = createApp(App);
Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DNS,
});
app.component("v-select", vSelect);
// eslint-disable-next-line vue/multi-word-component-names
app.component("Datepicker", VueDatePicker);
app.component("EasyDataTable", Vue3EasyDataTable);
app.component("vue3-slider", slider);
app.use(createPinia());
app.use(VueApexCharts);
app.use(router);

app.mount("#app");
/* console.log(import.meta.env) */
