import axios from "axios";
import {
  sendingRefreshToken,
  setingActivity,
  getAccessToken,
  logout,
  gettingActivity,
  snoozeRequest,
} from "./services/authInterceptor";

axios.defaults.baseURL = import.meta.env.VITE_API_BACKOFFICE;

// Request interceptor for API calls
axios.interceptors.request.use(
  async function (config) {
    const accessToken = getAccessToken();
    const defautHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    config.headers = { ...config.headers, ...defautHeaders };
    if (accessToken !== null) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
// Response interceptor for API calls
axios.interceptors.response.use(
  (response) => {
    setingActivity();
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    const isRefreshTokenRequest =
      originalRequest.url.includes("api/auth/token");
    const signInActivity = gettingActivity();

    //* 401 Not authenticated user, Case: there is an Authorization header but token is wrong or expired
    if (error.response.status === 401) {
      if (!originalRequest.retry && !isRefreshTokenRequest && signInActivity) {
        if (localStorage.getItem("refreshTokenWait") !== null) {
          await snoozeRequest();
        } else {
          originalRequest.retry = true;
          localStorage.setItem("refreshTokenWait", "true");
          await sendingRefreshToken();
          localStorage.removeItem("refreshTokenWait");
        }
        return axios(originalRequest);
      }

      logout();
    }
    //* 403 Not authorized user
    if (error.response.status === 403) {
      if (!error.config.headers.Authorization) { // check if there is Authorization header
        if (!originalRequest.retry && !isRefreshTokenRequest && signInActivity) {// if there is no Authorization header but request is for Refresh token then
          if (localStorage.getItem("refreshTokenWait") !== null) { //flag for holding all requests until we have new token
            await snoozeRequest();
          } else {
            originalRequest.retry = true;
            localStorage.setItem("refreshTokenWait", "true");
            await sendingRefreshToken();
            localStorage.removeItem("refreshTokenWait");
          }
          return axios(originalRequest);
        }
        logout();
      }
      // else {} If there is Authorzation header then token is valid but permission is not valid (User is logged in but doesn't have permissions for resource)
    }
    if (error.response.status === 400 && isRefreshTokenRequest) { // 400 Bad Request, contact backend devs
      logout();
    }
    return Promise.reject(error);
  }
);
