import { defineStore } from "pinia";

export const useLoaderStore = defineStore({
  id: "loader",
  state: () => ({
    isLoading: <boolean>false,
  }),
  getters: {
    getIsLoading: (state) => state.isLoading,
  },
  actions: {
    toggleLoader(payload:boolean) {
      this.isLoading = payload
    },
  },
});